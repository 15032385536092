import { Cmp, api } from ":mods";
import { ENDPOINTS } from "../const";
// import { Login } from "../model";
// import { actions } from "../store";

export async function sendConfirmationEmail(email: string) {
	return api
		.post(ENDPOINTS.post_password_reset, { email })
		.then((res) => {
			return Promise.resolve(res.data) ;
		})
		.catch((err) => {
			return Promise.reject(err);
		});
}
